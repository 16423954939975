<template>
  <section class="bg-agrogo-green-2">
    <!-- SECCION NUMERACION -->
    <div
      class="relative grid grid-cols-1 bg-agrogo-yellow-1 min-h-88 md:max-h-103"
    >
      <div
        class="flex items-center pt-4 mb-4 pl-4 space-x-2 md:pt-2 md:pl-20 md:space-x-9 md:items-start"
      >
        <span
          class="font-nexa font-black text-5xl md:text-8xl text-agrogo-yellow-2"
          >02</span
        >
        <div class="grid grid-cols-1 md:pt-3 md:w-1/2">
          <span
            class="font-nexa font-black text-agrogo-green-2 text-sm text-left md:text-2xl md:text-center"
            >Verificación <span class="font-normal">de Datos</span></span
          >
          <div
            class="items-center justify-center space-x-4 md:space-x-5 lg:space-x-6 mb-2 hidden md:inline-flex"
          >
            <span class="icon-punto text-white md:text-2xl"></span>
            <span class="icon-punto text-agrogo-green-3 md:text-2xl"></span>
            <span class="icon-punto text-white md:text-2xl"></span>
          </div>
        </div>
      </div>
      <div
        class="flex items-center justify-center space-x-4 md:space-x-5 lg:space-x-6 mb-2 md:hidden"
      >
        <span class="icon-punto text-agrogo-green-3 md:text-2xl"></span>
        <span class="icon-punto text-white md:text-2xl"></span>
        <span class="icon-punto text-white md:text-2xl"></span>
      </div>
      <div class="absolute top-2.5 right-2.5">
        <button class="btn-close">
          <span class="icon-x"></span>
        </button>
      </div>
    </div>
    <!-- CONTENIDO -->
    <div
      class="flex flex-col items-center justify-center mt-20 md:mt-16 pb-24 md:pb-36"
    >
      <img
        src="@/assets/images/loaderagrogo.gif"
        class="w-24 mb-16 md:mb-6"
        alt=""
      />
      <span class="font-nexa font-normal text-base md:text-2xl text-white"
        >Estamos procesando tus datos</span
      >
    </div>
  </section>
</template>
